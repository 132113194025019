<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">代理商管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">绑定机构</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                placeholder="请输入机构名称"
                size="small"
                v-model="agencyOrganRelationName"
                clearable
              >
              </el-input>
            </div>
            <el-button
              style="background: #5c6be8; color: white;border-radius:20px;"
              @click="getAgentbind"
              >查询</el-button
            >
            <el-button
              style="background: #5c6be8; color: white;border-radius:20px;"
              @click="addOrEdit('绑定机构')"
              >绑定机构</el-button
            >
            <el-button
              @click="$router.back()"
              style="background: #5c6be8; color: white; border-radius:20px;"
              >返回</el-button
            >
          </div>
        </div>

        <!-- 表格区域 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              :height="tableHeight"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="机构名称"
                align="center"
                show-overflow-tooltip
                prop="compName"
                minWidth="280"
              />
              <el-table-column
                label="机构地址"
                align="center"
                show-overflow-tooltip
                prop="compAddress"
                minWidth="260"
              />
              <el-table-column
                label="法人姓名"
                align="center"
                show-overflow-tooltip
                prop="compLegal"
              />
              <el-table-column
                label="联系电话"
                align="center"
                show-overflow-tooltip
                prop="compPhone"
                minWidth="150"
              />
              <!-- <el-table-column
                label="结算方式"
                align="center"
                show-overflow-tooltip
                prop="settleStyle"
                minWidth="150"
              >
                <template v-slot="{ row }">
                  {{
                    $setDictionary('AGENCY_SETTLE_STYLE',row.settleStyle)
                  }}
                </template>
              </el-table-column> -->
                <el-table-column
                label="付费形式"
                align="center"
                show-overflow-tooltip
                prop="compPhone"
                minWidth="150"
              >
                  <template slot-scope="scope">
                  {{ $setDictionary("AGENCY_PAYMENT_METHOD", scope.row.paymentMethod) }}
                </template>
              </el-table-column>

              <!-- <el-table-column
                label="结算标准"
                align="center"
                prop="settleStandard"
                minWidth="100"
              >
                <template v-slot="{ row }">
                  {{
                      row.settleStyle === "10"
                      ? row.settleStandard + "%"
                      : row.settleStyle === "40" || row.settleStyle === "70"
                      ? row.settleStandard + "元/人/课时"
                      : row.settleStandard + "元/人"
                  }}
                </template>
              </el-table-column> -->
              <el-table-column
                label="有效时间"
                align="center"
                show-overflow-tooltip
                minWidth="280"
              >
                <template v-slot="{ row }" >
                  <span v-if="row.organStartDate&&row.organEndDate">
                    {{
                      row.organStartDate.replaceAll("/", "-")
                    }}&nbsp;&nbsp;至&nbsp;&nbsp;{{
                      row.organEndDate.replaceAll("/", "-")
                    }}
                  </span>
                  <span v-else>--</span>
                </template> </el-table-column
              >>
              <el-table-column
                label="备注"
                align="center"
                show-overflow-tooltip
                minWidth="150" 
              >
                <template v-slot="{row}">
                    <span style="color:red;">{{row.remarks}}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="100" fixed="right">
                <template v-slot="{ row }">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="addOrEdit('编辑', row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="deleted(row)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="pagination"
          @sizeChange="sizeChange"
          @getData="currentChange"
        />
      </div>
    </div>

    <!-- 对话框区域 用于编辑和添加 -->
    <el-dialog
      @closed="clearForm"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="560px"
    >
      <el-form
        size="small"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="机构名称" prop="compName">
          <el-input
            v-if="isEdit"
            :disabled="true"
            v-model="ruleForm.compName"
          ></el-input>
          <el-select
            style="width: 420px"
            v-else
            size="small"
            v-model="compId"
            value-key="compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
            @change="bindChange"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择合同" prop="contractId">
          <div style="display: flex; align-items: center;justify-content: center;">
            <el-select
              size="small"
              style="flex: auto"
              v-model="ruleForm.contractId"
              value-key="contractId"
              clearable
              placeholder="请选择合同"
              @change="contractChange"
            >
              <el-option
                v-for="item in contractList"
                :key="item.contractId"
                :label="item.contractName"
                :value="item.contractId"
              >{{item.contractName}}({{item.startDate}}-{{item.endDate}})</el-option>
            </el-select>
            <el-button style="margin-left: 10px;" type="text" @click="previewTheContract(ruleForm.contractId)" size="small">预览合同</el-button>
          </div>
        </el-form-item>
        <!-- <el-form-item label="结算方式" prop="settleStyle">
          <el-select
            v-model="ruleForm.settleStyle"
            placeholder="请选择结算方式"
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算标准" prop="settleStandard">
          <el-input
            style="width: 200px"
            v-if="ruleForm.settleStyle == '10'"
            v-model="ruleForm.settleStandard"
            placeholder="请输入结算标准"
            size="small"
            @input="filter"
          >
            <template slot="append">%</template>
          </el-input>

          <el-input
            v-else-if="
              ruleForm.settleStyle == '40' || ruleForm.settleStyle == '70'
            "
            style="width: 200px"
            v-model="ruleForm.settleStandard"
            placeholder="请输入结算标准"
            size="small"
            @input="filter"
          >
            <template slot="append">元/人/课时</template>
          </el-input>

          <el-input
            style="width: 200px"
            v-else
            v-model="ruleForm.settleStandard"
            placeholder="请输入结算标准"
            size="small"
            @input="filter"
          >
            <template slot="append">元/人</template>
          </el-input>
        </el-form-item> -->

        <el-form-item prop="paymentMethod" label="付费形式">
          <el-select v-model="ruleForm.paymentMethod" clearable placeholder="请选择">
    <el-option
      v-for="item in paymentOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
          <!-- <el-date-picker
            size="small"
            v-model="ruleForm.datePicker"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="dateChange"
          >
          </el-date-picker> -->
        </el-form-item>
        <el-form-item prop="datePicker" label="有效时间">
          <el-date-picker
            size="small"
            v-model="ruleForm.datePicker"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="background: #5c6be8; color: white;border-radius:20px;" @click="cancel">取 消</el-button>
        <el-button style="background: #5c6be8; color: white;border-radius:20px;" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog title="预览合同" :visible.sync="previewTheContractState" width="950px" top="2%" center>
      <ContractManagementAdd :seeTH="true" :key="ruleForm.contractId" :seeContractId="ruleForm.contractId" v-if="previewTheContractState" />
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import ContractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
export default {
  name: "BindingMechanism",
  components: {
    Empty,
    PageNum,
    ContractManagementAdd
  },
  mixins: [List],
  data() {
    const validateSettleStandard = (_, value, callback) => {
      // 按比例结算时的正则
      const reg = /^100$|^(\d|[1-9]\d)(\.\d+)*$/;
      value = value.toString();
      if(value.split(".").length>2 || value.indexOf(".")===0) return callback("请输入正确的结算标准");
      if (this.ruleForm.settleStyle === "10") {
        if (reg.test(value)) return callback();
        callback("比例结算不能超过100%");
      } else {
        const zs = value.split(".")[0];
        if (zs.length > 7) return callback("整数部分长度不能超过7");
        callback();
      }
    };
    return {
      CompanyList: [], //机构数据
      contractList: [], //代理商合同
      compId: {
        compAddress: "",
        compId: "",
        compLegal: "",
        compName: "",
        compPhone: "",
        remarks: "",
      },
      agencyId: "",
      tableData: [], // 绑定的机构数据
      fileList2: [],
      options: [], //结算方式
      agencyOrganRelationName: "", //查询的机构名称
      dialogVisible: false,
      dialogTitle: "", // 对话框名字
      isEdit: false,
      //   添加或修改表单的数据
      ruleForm: {
        agencyOrganRelationId: "",
        compAddress: "",
        compId: "",
        contractId: "",
        compLegal: "",
        compName: "",
        compPhone: "",
        organEndDate: "",
        organStartDate: "",
        // settleStandard: "",
        // settleStyle: "",
        datePicker: [],
        paymentMethod:'10',
      },
      paymentOptions:[
      ],
      //   表单规则
      rules: {
        compName: [
          {
            required: true,
            message: "请输入机构名称",
            trigger: "blur",
          },
        ],
        contractId: [
          {
            required: true,
            message: "请选择合同",
            trigger: "change",
          },
        ],
        settleStyle: [
          {
            required: true,
            message: "请选择结算方式",
            trigger: "blur",
          },
        ],
        settleStandard: [
          {
            required: true,
            message: "请输入结算标准",
            trigger: "blur",
          },
          {
            validator: validateSettleStandard,
            trigger: "blur",
          },
        ],
        paymentMethod: [
          {
            required: true,
            message: "请选择付费形式",
            trigger: "change",
          },
        ],
        datePicker: [
          {
            required: true,
            message: "请选择有效时间",
            trigger: "blur",
          },
        ],
      },
      pagination: {
        total: 0,
        size: 10,
        current: 1,
      },
      backupsRemarks:"",
      previewTheContractState:false,//预览合同
    };
  },
  created() {
    this.agencyId = this.$route.query.agencyId;
    this.getAgentbind();
    this.getOptions();
    this.getContractList();
    this.getTableHeight();
  },
  methods: {
    // 切换合同
    contractChange(val){
      if(!val){
        return
      }
      const contractObject = this.contractList.find(e=>e.contractId === val)
      this.ruleForm.datePicker = [contractObject.startDate.replaceAll("/", "-"),contractObject.endDate.replaceAll("/", "-")]
    },
    // 预览合同
    previewTheContract(contractId){
      if(!contractId){
        this.$message.warning('请选择合同！')
        return
      }
      this.previewTheContractState = true;
    },
    // 获取代理商合同列表
    getContractList() {
      this.$post("/biz/new/bill/contract/agentContract", {agencyId:this.agencyId }).then(
        (res) => {
          this.contractList = res.data || [];
        }
      );
    },
    // 获取绑定机构的信息(查询通用)
    getAgentbind() {
      const { agencyId, agencyOrganRelationName } = this;
      const { current, size } = this.pagination;
      this.$post("/agency/ma-agency-static/organ/info", {
        agencyId,
        agencyOrganRelationName,
        pageNum: current,
        pageSize: size,
      }).then((res) => {
        console.log(res.data.list)
        res.data.list.forEach((item,index) => {

          if(item.organStartDate&&item.organEndDate){
            item["datePicker"] = [
              item.organStartDate.replaceAll("/", "-"),
              item.organEndDate.replaceAll("/", "-"),
            ];
          }
          if(index===0) item.remarks=this.backupsRemarks;

        });
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
      });
    },
    // 添加或编辑数据
    addOrEdit(type, row) {
      this.isEdit = type === "编辑" ? true : false;
      this.dialogTitle = type;
      if (this.isEdit) {
        // 编辑
        const { agencyOrganRelationId } = row;
        this.$post("/agency/ma-agency-static/organ/relationInfo", {
          agencyOrganRelationId,
        }).then((res) => {
          if(res.data.organStartDate&&res.data.organStartDate){{
            res.data["datePicker"] = [
              res.data.organStartDate.replaceAll("/", "-"),
              res.data.organEndDate.replaceAll("/", "-"),
            ];
          }}

          this.ruleForm = res.data;
          this.dialogVisible = true;
        });
      } else {
        // 添加
        this.dialogVisible = true;
      }
    },
    // 对话框确定按钮
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const { ruleForm, isEdit } = this;
          if (!isEdit) ruleForm["agencyId"] = this.agencyId;
          if(ruleForm.datePicker&&ruleForm.datePicker.length===2){
            ruleForm.organStartDate = ruleForm.datePicker[0]
            ruleForm.organEndDate = ruleForm.datePicker[1]
          }
          this.$post(
            "/agency/ma-agency-static/organ/insertOrUpdate",
            ruleForm
          ).then(() => {
            this.$message({
              type: "success",
              message: "绑定成功",
            });
            this.getAgentbind();
            this.dialogVisible = false;
          });
        }
      });
    },
    //取消对话框
    cancel() {
      this.backupsRemarks = "";
      this.dialogVisible = false;
    },
    // 清空表单
    clearForm() {
      this.ruleForm = {
        agencyOrganRelationId: "",
        compAddress: "",
        compId: "",
        contractId: "",
        compLegal: "",
        compName: "",
        compPhone: "",
        organEndDate: "",
        organStartDate: "",
        // settleStandard: "",
        // settleStyle: "",
        datePicker: [],
        paymentMethod:'10'
      };
      this.compId = {
        compAddress: "",
        compId: "",
        compLegal: "",
        compName: "",
        compPhone: "",
        remarks: "",
      };
      this.CompanyList = [];
      this.$refs["ruleForm"].resetFields();
    },
    // 分页器显示的条数改变
    sizeChange(val) {
      this.pagination.size = val;
      this.getAgentbind();
    },
    // 分页器当前页改变时
    currentChange(val) {
      if (val < 1) val = 1;
      this.pagination.current = val;
      this.getAgentbind();
    },
    // 获取查询的机构数据
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/list/containsRemarks", { compName: query }).then(
          (res) => {
            this.CompanyList = res.data || [];
          }
        );
      } else {
        this.CompanyList = [];
      }
    },
    // 选择的机构更改时
    bindChange(value) {
      this.backupsRemarks=value.remarks;
      if (!value)
        value = {
          compAddress: "",
          compId: "",
          compLegal: "",
          compName: "",
          compPhone: "",
          remarks: "",
        };
      const { ruleForm } = this;
      Object.keys(value).forEach((item) => {
        ruleForm[item] = value[item];
      });
    },
    // 获取码值
    getOptions() {
      const agencySettleStyle = this.$setDictionary(
        "AGENCY_SETTLE_STYLE",
        "list"
      );
      for (const key in agencySettleStyle) {
        this.options.push({
          value: key,
          label: agencySettleStyle[key],
        });
      }
      const agencyPaymentMethod = this.$setDictionary(
        "AGENCY_PAYMENT_METHOD",
        "list"
      );
      for (const key in agencyPaymentMethod) {
        this.paymentOptions.push({
          value: key,
          label: agencyPaymentMethod[key],
        });
      }
    },
    // 过滤非法字符
    filter(value){
        value=value.replace(/[^0-9\.]/g,"");
        this.ruleForm.settleStandard=value;
    },
    // 删除
    deleted(row) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:"confirmButton-feifan",
        cancelButtonClass:"cancelButton-feifan"
      }).then(() => {
        this.$post("/agency/ma-agency-static/organ/del", {
          agencyId: this.agencyId,
          agencyOrganRelationId: row.agencyOrganRelationId,
        }).then(() => {
          this.backupsRemarks="";
          this.getAgentbind();
        });
      });
    },
    // 获取表格高度
    getTableHeight(opDom = false, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style>
    .confirmButton-feifan{
        background-color:#5c6be8 !important;
        color:white;
        border:none;
        border-radius: 20px;
    }
    .cancelButton-feifan{
        border-radius: 20px;
    }
</style>
<style lang="less" scoped>
.searchbox {
  width: 100%;
  justify-content: flex-start;
  .el-button:nth-last-child(1) {
    position: absolute;
    right: 1.25rem;
  }
}

</style>